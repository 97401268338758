export interface SelectField {
  component: 'select-field'
  id: string
  name: string
  placeholder: string
  options: string
  is_required: boolean
  validation_error: string
  exclude_field_in_tracking: boolean
}

interface SelectFieldOption {
  value: string
  text: string
}
export function normalizeSelectField(field: SelectField) {
  /**
  * TODO:
  * Either the the options are key,key or key:value, so we split them by comma
  * @example 'option1,option2,option3'
  * @example 'option1:Option 1,option2:Option 2,option3:Option 3'
  */

  function extractOptions(options: string): SelectFieldOption[] {
    function extractOption(option: string): SelectFieldOption {
      const [value, text] = option.split(':')
      return {
        value,
        text: text || value,
      }
    }

    return options.split(',').map(extractOption)
  }

  const options = computed<SelectFieldOption[]>(() => extractOptions(field.options || ''))
  return {
    component: field.component,
    id: field.id,
    name: field.name,
    placeholder: field.placeholder,
    options: options.value,
    isRequired: field.is_required,
    validationError: field.validation_error,
    excludeFieldInTracking: field.exclude_field_in_tracking,
  }
}
