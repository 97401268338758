import { emailRegex } from 'data/patterns/email'

export function useFormFieldValidation() {

    const isEmailFieldAndNotValid = (formField: string, isRequired: boolean) => {
        return (formField && !emailRegex.test(formField)) || (isRequired && !formField)
    }

    const isRequiredFieldAndEmpty = (formField: string, isRequired: boolean) => {
        return !formField && isRequired
    }
    return {
        isEmailFieldAndNotValid,
        isRequiredFieldAndEmpty,
    }
}
